import React from "react"
import { graphql } from "gatsby"
import { useSiteContext } from "@organic-return/foundation-gatsby-plugin-agility/src/components/common/SiteContext"
import MainPage from "@organic-return/foundation-gatsby-plugin-agility/src/MainPage"
import loadable from "@loadable/component"
const ContactForm = loadable(() => import("@organic-return/foundation-gatsby-plugin-agility/src/components/agility-pageModules/ContactForm"))
import {
    ContactCard,
    EContactCardLayout,
} from "@organic-return/foundation-react/src/components/Contact/ContactCard"

export const query = graphql`
    query($nodeID: String!) {
        agilityVideos(id: {eq: $nodeID}) {
            customFields {
              content
              title
              videoID
            }
            id
            seo {
              metaKeywords
              metaDescription
            }
        }
        allPerson(filter: {first: {eq: "Chris"}, last: {eq: "Klug"}}) {
            nodes {
              last
              bio
              category
              company
              email
              first
              fullName
              mlsID
              officeName
              phone
              phoneMobile
              phoneOffice
              photos {
                dimensions
                url
              }
            }
        }
    }
`

const VideoPage = ({ pageContext, data, location }: any) => {
    const siteContext = useSiteContext()
    const video = data.agilityVideos.customFields
    const agent = data.allPerson.nodes.length > 0 && data.allPerson.nodes[0]
    const formProps = {
        module: {
            customFields: {
                title: "Contact Us"
            }
        }
    }
    return (
        <MainPage
            className="page-video"
            seoProps={{
                title: video.title,
                description: data.agilityVideos.seo?.metaDescription,
                keywords: data.agilityVideos.seo?.metaKeywords,
                location: location
            }}
            headerProps={{
                gradient: false,
                ...siteContext.headerProps,
            }}
        >
            <>
                <div className="max-w-screen-2xl mx-auto px-4 grid grid-cols-2 gap-4">
                    <div className="col-span-2">
                        <iframe width="100%" height="576" src={`https://www.youtube.com/embed/${video.videoID}?modestbranding=1;autohide=1&amp;showinfo=0&amp;controls=0;feature=oembed`}></iframe>
                    </div>
                    <div className="col-span-2 xl:col-span-1">
                        <h2>{video.title}</h2>
                        <div dangerouslySetInnerHTML={{ __html: video.content }}></div>
                    </div>
                    <div className="col-span-2 xl:col-span-1">
                        <ContactCard
                            contact={agent}
                            layout={EContactCardLayout.pageSection}
                            maxWidthImage={200}
                            className="w-full inverted"
                            showVCard={false}
                            showBio={true}
                        />
                    </div>
                </div>
                <ContactForm {...formProps}></ContactForm>
            </>
        </MainPage>
    )
}

export default VideoPage